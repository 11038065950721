// import { BrowserRouter as Router, Route, Switch } from "react-router-dom"; // eslint-disable-line
import { Route, Routes as ReactRoutes, Navigate } from "react-router-dom";

import DateTimeProvider from "context/DateTime/DateTimeProvider";
import MainLayout from "Layout/MainLayout/MainLayout";
import MainMenu from "features/views/Home/MainMenu/MainMenu";


const Routes = () => {

  return (
    <DateTimeProvider>
      <MainLayout>
        <ReactRoutes>
          <Route
            path="/"
            element={<MainMenu />}
          />         
        </ReactRoutes>
      </MainLayout>
    </DateTimeProvider>
  );
};

export default Routes;
