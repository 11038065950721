import axios from 'axios';
import { NewRCS } from './types/NewRCS';
import getRequestConfig from '../../functions/getRequestConfig';

const fetchRCS = async (jwt: string, URL: string) => {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.get(`${URL}/channels/RCS`, config);
  console.log("response: ", response);
  return response.data;
};
const createRCS = async (jwt: string, URL: string, RCS: NewRCS) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const RCSJson = JSON.stringify(RCS);
  const response = await axios.post(`${URL}/channels/RCS`, RCSJson, config);
  return response.data;
};
const deleteRCS = async (jwt: string, URL: string, id: string) => {
  const config = getRequestConfig.basic(jwt);
  await axios.delete(`${URL}/channels/RCS/${id}`, config);

  return id;
};
const updateRCS = async (jwt: string, URL: string, RCS: any) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const RCSJson = JSON.stringify(RCS);

  const response = await axios.put(
    `${URL}/channels/RCS/${RCS.id}`,
    RCSJson,
    config
  );
  return response.data;
};

const RCSService = {
  fetchRCS,
  createRCS,
  deleteRCS,
  updateRCS,
};

export default RCSService;
