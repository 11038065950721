import axios from 'axios';
import getRequestConfig from '../../functions/getRequestConfig';
import { UserDataReports } from './types/UserDataReports';
import { JsReportData } from './types/JsReportData';

const fetchJsReport = async (jwt: string, URL: string, data: JsReportData) => {
  const requestConfig = getRequestConfig.contentTypeJson(jwt);

  const response = await axios.post(`${URL}/JsReport`, data, {
    ...requestConfig,
    responseType: 'blob',
  });
  return response.data;
};
const postMessagesLogs = async (jwt: string, URL: string, data: UserDataReports) => {
  const requestConfig = getRequestConfig.contentTypeFormData(jwt);
  const dataJson = JSON.stringify(data);

  const response = await axios.post(
    `${URL}/Reports/analytics/messagesLogs`,
    dataJson,
    requestConfig
  );
  return response.data;
};
const postConversationsLogs = async (jwt: string, URL: string, data: UserDataReports) => {
  const requestConfig = getRequestConfig.contentTypeFormData(jwt);
  const dataJson = JSON.stringify(data);

  const response = await axios.post(
    `${URL}/Reports/analytics/conversationsLogs`,
    dataJson,
    requestConfig
  );
  return response.data;
};

const postConversationsTrends = async (
  jwt: string,
  URL: string,
  data: UserDataReports
) => {
  const requestConfig = getRequestConfig.contentTypeFormData(jwt);
  const dataJson = JSON.stringify(data);

  const response = await axios.post(
    `${URL}/Reports/analytics/conversations_trends`,
    dataJson,
    requestConfig
  );
  return response.data;
};
const postTimeDistribution = async (
  jwt: string,
  URL: string,
  data: UserDataReports
) => {
  const requestConfig = getRequestConfig.contentTypeFormData(jwt);
  const dataJson = JSON.stringify(data);

  const response = await axios.post(
    `${URL}/Reports/analytics/time_distribution`,
    dataJson,
    requestConfig
  );
  return response.data;
};
const postUserPerformance = async (
  jwt: string,
  URL: string,
  data: UserDataReports
) => {
  const requestConfig = getRequestConfig.contentTypeFormData(jwt);
  const dataJson = JSON.stringify(data);

  const response = await axios.post(
    `${URL}/Reports/productivity/user_performance`,
    dataJson,
    requestConfig
  );
  return response.data;
};
const postResponseTimes = async (
  jwt: string,
  URL: string,
  data: UserDataReports
) => {
  const requestConfig = getRequestConfig.contentTypeFormData(jwt);
  const dataJson = JSON.stringify(data);

  const response = await axios.post(
    `${URL}/Reports/productivity/response_times`,
    dataJson,
    requestConfig
  );
  return response.data;
};
const postDetailsByUserGroup = async (
  jwt: string,
  URL: string,
  data: UserDataReports
) => {
  const requestConfig = getRequestConfig.contentTypeFormData(jwt);
  const dataJson = JSON.stringify(data);

  const response = await axios.post(
    `${URL}/Reports/productivity/details_by_user_group`,
    dataJson,
    requestConfig
  );
  return response.data;
};
const postEndings = async (jwt: string, URL: string, data: UserDataReports) => {
  const requestConfig = getRequestConfig.contentTypeFormData(jwt);
  const dataJson = JSON.stringify(data);

  const response = await axios.post(
    `${URL}/Reports/productivity/endings`,
    dataJson,
    requestConfig
  );
  return response.data;
};
const postSummary = async (jwt: string, URL: string, data: UserDataReports) => {
  const requestConfig = getRequestConfig.contentTypeFormData(jwt);
  const dataJson = JSON.stringify(data);

  const response = await axios.post(
    `${URL}/Reports/channel_usage/summary`,
    dataJson,
    requestConfig
  );
  return response.data;
};
const postChannelDetail = async (
  jwt: string,
  URL: string,
  data: UserDataReports
) => {
  const requestConfig = getRequestConfig.contentTypeFormData(jwt);
  const dataJson = JSON.stringify(data);

  const response = await axios.post(
    `${URL}/Reports/channel_usage/channel_detail`,
    dataJson,
    requestConfig
  );
  return response.data;
};
const postStatistics = async (jwt: string, URL: string, data: UserDataReports) => {
  const requestConfig = getRequestConfig.contentTypeFormData(jwt);
  const dataJson = JSON.stringify(data);

  const response = await axios.post(
    `${URL}/Reports/flow/statistics`,
    dataJson,
    requestConfig
  );
  return response.data;
};
const postSatisfactionSurvey = async (
  jwt: string,
  URL: string,
  data: UserDataReports
) => {
  const requestConfig = getRequestConfig.contentTypeFormData(jwt);
  const dataJson = JSON.stringify(data);

  const response = await axios.post(
    `${URL}/Reports/customer_satisfaction/satisfaction_survey`,
    dataJson,
    requestConfig
  );
  return response.data;
};

//   export const postAll = async (jwt: string, URL: string, data: UserDataReports) => {
//     const postFunctions = [
//       postRecords,
//       postConversationsTrends,
//       postTimeDistribution,
//       postUserPerformance,
//       postResponseTimes,
//       postDetailsByUserGroup,
//       postEndings,
//       postSummary,
//       postChannelDetail,
//       postStatistics,
//       postSatisfactionSurvey,
// ];
//     const promises = postFunctions.map((postFunction) => postFunction(jwt, URL, data));
//     const results = await Promise.all(promises);
//     return results;
//   };

const reportsService = {
  postMessagesLogs,
  postConversationsLogs,
  postConversationsTrends,
  postTimeDistribution,
  postUserPerformance,
  postResponseTimes,
  postDetailsByUserGroup,
  postEndings,
  postSummary,
  postChannelDetail,
  postStatistics,
  postSatisfactionSurvey,
  fetchJsReport,
};
export default reportsService;
