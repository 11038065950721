import { Box, Card, Typography } from '@mui/material'
// import React from 'react'

const panelList = [{panel: 1, title:'Tareas'}, {panel: 2, title:'Eventos'} ,{panel: 3, title:'Mi Actividad'}]

const MenuCards = () => {
  return (
    <Box display="flex" gap={2} width={'1100px'} marginTop={'80px'}>
      {panelList.map((panel, index) => (
        <Card
          sx={{
            pb: 1.5,
            ml: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            backgroundColor: (theme) => theme.palette.background.default,
            width: "100%",
          }}
          key={index}
        >
          <Box 
          //@ts-ignore
          bgcolor={(theme) => theme.palette.background.dropdownMenu} px={2} py={1} >
            <Box gap={1} alignItems={"center"}>
              <Typography variant={"subtitle2"}>{panel.title}</Typography>
            </Box>
          </Box>
          <Box sx={{
            minHeight: '150px',
            padding: 2,
            paddingTop: 0,
            wordWrap: 'break-word',
          }}>
          </Box>
        </Card>
      ))}
    </Box>
  );
}

export default MenuCards