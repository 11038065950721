import {
  createSlice,
  createAsyncThunk,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit/dist';
import { RootState } from '../../rootReducer';
// Functions
import { initRequestData } from '../../functions/initRequestData';
// Types
import { RCSState } from './types/RCSState';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { RequestStatus } from 'redux/types/RequestStatus';
import { NewRCS } from './types/NewRCS';
// Service
import RCSService from './RCSService';

const initialState: RCSState = {
  RCS: [],
  status: {
    fetch: 'idle',
    create: 'idle',
    delete: 'idle',
  },
};

export const fetchRCS = createAsyncThunk(
  'RCS/fetchRCS',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await RCSService.fetchRCS(jwtToken, URL_CONVERSATIONS);

    return response;
  }
);

export const createRCS = createAsyncThunk(
  'RCS/createRCS',
  async (RCS: NewRCS, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await RCSService.createRCS(
      jwtToken,
      URL_CONVERSATIONS,
      RCS
    );

    return response;
  }
);

export const deleteRCS = createAsyncThunk(
  'RCS/deleteRCS',
  async (RCSId: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await RCSService.deleteRCS(
      jwtToken,
      URL_CONVERSATIONS,
      RCSId
    );

    return response;
  }
);

const RCSSlice = createSlice({
  name: 'RCS',
  initialState,
  reducers: {
    changeRCSCreateStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.status.create = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchRCS.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchRCS.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        state.RCS = action.payload;
      })
      .addCase(createRCS.pending, (state) => {
        state.status.create = 'loading';
      })
      .addCase(createRCS.fulfilled, (state, action) => {
        state.status.create = 'succeeded';
        state.RCS.push(action.payload);
      })
      .addCase(createRCS.rejected, (state, action) => {
        state.status.create = 'rejected';
        console.log(action.error);
      })
      .addCase(deleteRCS.pending, (state) => {
        state.status.delete = 'loading';
      })
      .addCase(deleteRCS.fulfilled, (state, action) => {
        state.status.delete = 'succeeded';
        state.RCS = state.RCS.filter((RCS) => RCS.id !== action.payload);
      });
  },
});

const selectRCSState = (state: RootState) => state.RCS;
export const selectAllRCS = createSelector(
  selectRCSState,
  (state) => state.RCS
);
export const selectRCSFetchStatus = createSelector(
  selectRCSState,
  (state) => state.status.fetch
);
export const selectRCSCreateStatus = createSelector(
  selectRCSState,
  (state) => state.status.create
);
export const selectRCSDeleteStatus = createSelector(
  selectRCSState,
  (state) => state.status.delete
);

export const { changeRCSCreateStatus } = RCSSlice.actions;

export default RCSSlice.reducer;
