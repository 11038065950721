import { Box, Divider, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next';
import MenuIcons from './components/MenuIcons';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/features/userSlice/userSlice';
import MenuCards from './components/MenuCards';

const MainMenu = () => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  return (
    <Box className={"home-fadeinup"}>
      {/* <Box
        style={{
          display: "flex",
          marginTop: "10px",
          marginBottom: "10px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h5"
          fontWeight={600}
          sx={{ color: (theme) => theme.palette.text.secondary }}
        >
          {t("global.applications")}
        </Typography>
      </Box> */}
      <Box
        display={"flex"}
        margin={2}
        sx={{ flexWrap: "wrap", gap: "1rem 3rem" }}
      >
        <MenuIcons user={user} />
      </Box>
      <Box
        style={{
          display: "flex",
          marginTop: "20px",
          marginBottom: "10px",
        }}
      >
        {/* <Typography
          variant="h5"
          fontWeight={600}
          sx={{ color: (theme) => theme.palette.text.secondary }}
        >
          {t('settingsView.comunicationChannels')}
        </Typography> */}
      </Box>
      <Box
        display={"flex"}
        margin={2}
        sx={{ flexWrap: "wrap", gap: "1rem 3rem", justifyContent: "center" }}
      >
        <MenuCards></MenuCards>
      </Box>
    </Box>
  );
}

export default MainMenu