import { useContext, useEffect, useState } from 'react';

export interface UseDateTime {
  getDateTime: () => Date;
}

export const useDateTimeInitialState: UseDateTime = {
  getDateTime: () => new Date(),
};

const useDateTime = (): UseDateTime => {
  const [serverTime, setServerTime] = useState<Date | null>(null);
  const [timeOffset, setTimeOffset] = useState<number | null>(null);


  function getDateTime(): Date {
    if (timeOffset !== null) {
      const localTime = new Date();
      const correctedTime = new Date(localTime.getTime() - timeOffset);

      return correctedTime;
    }

    return new Date();
  }

  return {
    getDateTime,
  };
};

export default useDateTime;
