import axios from 'axios';
import getRequestConfig from '../../functions/getRequestConfig';
//Types
import { IChannel } from '@trii/types/dist/Common/Channels';

const fetchRCSEdit = async (
  jwt: string,
  URL: string,
  id: string
): Promise<IChannel> => {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.get(`${URL}/channels/RCS/${id}`, config);
  return response.data;
};
const updateRCSEdit = async (jwt: string, URL: string, RCSEdit: IChannel) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const RCSEditJson = JSON.stringify(RCSEdit);

  const response = await axios.put(
    `${URL}/channels/RCS/${RCSEdit.id}`,
    RCSEditJson,
    config
  );
  return response.data;
};

async function syncTemplates(jwt: string, URL: string, channelId: string) {
  const config = getRequestConfig.basic(jwt);
  const response = await axios.post(
    `${URL}/TemplateMessages/${channelId}/synctemplates`,
    {},
    config
  );

  return response;
}

async function resetApiQrConnection(jwt: string, URL: string, channelId: string) {
  const config = getRequestConfig.basic(jwt);
  const response = await axios.post(
    `${URL}/channels/RCS/${channelId}/reload`,
    {},
    config
  );

  return response;
}

async function unlinkRCSEdit(jwt: string, URL: string, channelId: string) {
  const config = getRequestConfig.basic(jwt);
  const response = await axios.post(
    `${URL}/channels/RCS/${channelId}/signout`,
    {},
    config
  );

  return response;
}

const RCSEditService = {
  fetchRCSEdit,
  updateRCSEdit,
  syncTemplates,
  resetApiQrConnection,
  unlinkRCSEdit,
};

export default RCSEditService;
