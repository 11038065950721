import {
  createSlice,
  createAsyncThunk,
  createSelector,
  PayloadAction,
  Dispatch,
} from '@reduxjs/toolkit/dist';
import { RootState } from '../../rootReducer';
// Functions
import { initRequestData } from '../../functions/initRequestData';
// Types
import { RCSEditState } from './types/RCSEditState';
import { PermissionReadMessagesPayload } from 'redux/types/PermissionReadMessagesPayload';
import { PermissionStartConversationsPayload } from 'redux/types/PermissionStartConversationsPayload';
import {
  RCSServiceAccount,
  Permission,
  IChannel,
} from '@trii/types/dist/Common/Channels';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { ISchedule } from '@trii/types/dist/Common/Schedules';
// Service
import RCSEditService from './RCSEditService';
import { AssignMethod } from '@trii/types/dist/Conversations';
import { GroupInfo } from '@trii/types/dist/Conversations/Groups/Group';
import { UserInfo } from '@trii/types/dist/Users';

const initialState: RCSEditState = {
  RCSEdit: null,
  RCSEditName: null,
  status: {
    fetch: 'idle',
    update: 'idle',
    syncRCSEditCloudApiTemplates: 'idle',
    resetApiQrConnection: 'idle',
    unlinkRCSEdit: 'idle',
  },
};

export const fetchRCSEdit = createAsyncThunk(
  'RCSEdit/fetchRCSEdit',
  async (id: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await RCSEditService.fetchRCSEdit(
      jwtToken,
      URL_CONVERSATIONS,
      id
    );

    return response;
  }
);

export const updateRCSEdit = createAsyncThunk<IChannel, void, { state: RootState }>(
  'RCSEdit/updateRCSEdit',
  async (_, { getState, dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const RCSEdit = getState().RCSEdit.RCSEdit;
    await RCSEditService.updateRCSEdit(jwtToken, URL_CONVERSATIONS, RCSEdit);

    return RCSEdit;
  }
);

export const syncRCSEditCloudApiTemplates = createAsyncThunk(
  'RCSEdit/syncRCSEditCloudApiTemplates',
  async (channelId: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await RCSEditService.syncTemplates(
      jwtToken,
      URL_CONVERSATIONS,
      channelId
    );

    return response;
  }
);

export const resetApiQrConnection = createAsyncThunk(
  'RCSEdit/resetApiQrConnection',
  async (channelId: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await RCSEditService.resetApiQrConnection(
      jwtToken,
      URL_CONVERSATIONS,
      channelId
    );

    return response;
  }
);

export const unlinkRCSEdit = createAsyncThunk(
  'RCSEdit/unlinkRCSEdit',
  async (channelId: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await RCSEditService.unlinkRCSEdit(
      jwtToken,
      URL_CONVERSATIONS,
      channelId
    );

    return response;
  }
);

const RCSEditSlice = createSlice({
  name: 'RCSEdit',
  initialState,
  reducers: {
    changeName(state, action: PayloadAction<string>) {
      state.RCSEdit.name = action.payload;
    },
    changeUseInCampaigns(state, action: PayloadAction<boolean>) {
      state.RCSEdit.rcsBusiness.useInCampaigns = action.payload;
    },
    changeToken(state, action: PayloadAction<string>) {
      state.RCSEdit.rcsBusiness.token = action.payload;
    },
    changeReassign(state, action: PayloadAction<boolean>) {
      state.RCSEdit.rcsBusiness.reassign = action.payload;
    },
    changeReassignGroup(state, action: PayloadAction<string>) {
      state.RCSEdit.rcsBusiness.reassignGroupId = action.payload;
    },
    changeAutoResponseBusinessHours(state, action: PayloadAction<boolean>) {
      state.RCSEdit.rcsBusiness.autoResponseBusinessHours = action.payload;
    },
    changeAutoResponseOutOfBusinessHours(state, action: PayloadAction<boolean>) {
      state.RCSEdit.rcsBusiness.autoResponseOutOfBusinessHours = action.payload;
    },
    changeAutoResponseHoliday(state, action: PayloadAction<boolean>) {
      state.RCSEdit.rcsBusiness.autoResponseHoliday = action.payload;
    },
    changeSchedule(state, action: PayloadAction<ISchedule>) {
      const schedule = action.payload;

      state.RCSEdit.rcsBusiness.schedule = schedule;
    },
    changeReOpenTime(state, action: PayloadAction<number>) {
      state.RCSEdit.rcsBusiness.reOpenTime = action.payload;
    },
    changePauseBetweenMessages(state, action: PayloadAction<number>) {
      state.RCSEdit.rcsBusiness.pauseBetweenMessages = action.payload;
    },
    addPermission: {
      prepare: (data: {
        groupId?: string;
        userId?: string;
      }): { payload: Permission } => ({
        payload: {
          groupId: data.groupId || null,
          readMessages: false,
          startConversations: false,
          userId: data.userId || null,
        },
      }),
      reducer: (state, action: PayloadAction<Permission>) => {
        state.RCSEdit.permissions.push(action.payload);
      },
    },
    removePermission(
      state,
      action: PayloadAction<{ groupId?: string; userId?: string }>
    ) {
      let index = -1;

      if (action.payload.groupId) {
        index = state.RCSEdit.permissions.findIndex(
          (permission) => permission.groupId === action.payload.groupId
        );
      } else {
        index = state.RCSEdit.permissions.findIndex(
          (permission) => permission.userId === action.payload.userId
        );
      }

      state.RCSEdit.permissions.splice(index, 1);
    },
    changePermissionReadMessages(
      state,
      action: PayloadAction<PermissionReadMessagesPayload>
    ) {
      const { permissionGroupId, readMessages, permissionContactId } =
        action.payload;

      const permissionByGroupId = state.RCSEdit.permissions.find(
        (permission) => permission.groupId === permissionGroupId
      );
      const permissionByUserId = state.RCSEdit.permissions.find(
        (permission) => permission.userId === permissionContactId
      );

      if (permissionByGroupId) {
        permissionByGroupId.readMessages = readMessages;
      } else if (permissionByUserId) {
        permissionByUserId.readMessages = readMessages;
      }
    },
    changePermissionStartConversation(
      state,
      action: PayloadAction<PermissionStartConversationsPayload>
    ) {
      const { permissionGroupId, startConversations, permissionContactId } =
        action.payload;

      const permissionByGroupId = state.RCSEdit.permissions.find(
        (permission) => permission.groupId === permissionGroupId
      );
      const permissionByUserId = state.RCSEdit.permissions.find(
        (permission) => permission.userId === permissionContactId
      );

      if (permissionByGroupId) {
        permissionByGroupId.startConversations = startConversations;
      } else if (permissionByUserId) {
        permissionByUserId.startConversations = startConversations;
      }
    },

    changeAssignMethod(state, action: PayloadAction<AssignMethod>) {
      state.RCSEdit.assignMethod = action.payload;
    },
    changeAssignTo(
      state,
      action: PayloadAction<{ group: GroupInfo; user: UserInfo }>
    ) {
      state.RCSEdit.assignTo = {
        groupInfo: action.payload.group,
        userInfo: action.payload.user,
      };
    },
    changeAuthProviderX509(state, action: PayloadAction<string>) {
      state.RCSEdit.rcsBusiness.serviceAccount.auth_provider_x509_cert_url = action.payload;
    },
    changeAuthUri(state, action: PayloadAction<string>) {
      state.RCSEdit.rcsBusiness.serviceAccount.auth_uri = action.payload;
    },
    changeClientEmail(state, action: PayloadAction<string>) {
      state.RCSEdit.rcsBusiness.serviceAccount.client_email = action.payload;
    },
    changeClientId(state, action: PayloadAction<string>) {
      state.RCSEdit.rcsBusiness.serviceAccount.client_id = action.payload;
    },
    changeClientX509(state, action: PayloadAction<string>) {
      state.RCSEdit.rcsBusiness.serviceAccount.client_x509_cert_url = action.payload;
    },
    changePrivateKey(state, action: PayloadAction<string>) {
      state.RCSEdit.rcsBusiness.serviceAccount.private_key = action.payload;
    },
    changePrivateKeyId(state, action: PayloadAction<string>) {
      state.RCSEdit.rcsBusiness.serviceAccount.private_key_id = action.payload;
    },
    changeProjectId(state, action: PayloadAction<string>) {
      state.RCSEdit.rcsBusiness.serviceAccount.project_id = action.payload;
    },
    changeTokenUri(state, action: PayloadAction<string>) {
      state.RCSEdit.rcsBusiness.serviceAccount.token_uri = action.payload;
    },
    changeType(state, action: PayloadAction<string>) {
      state.RCSEdit.rcsBusiness.serviceAccount.type = action.payload;
    },
    changeUniverseDomain(state, action: PayloadAction<string>) {
      state.RCSEdit.rcsBusiness.serviceAccount.universe_domain = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchRCSEdit.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchRCSEdit.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        console.log('Fetched RCSEdit: ', action.payload);
        state.RCSEdit = action.payload;
        state.RCSEditName = action.payload.name;
      })
      .addCase(updateRCSEdit.pending, (state) => {
        state.status.update = 'loading';
      })
      .addCase(updateRCSEdit.fulfilled, (state, action) => {
        state.status.update = 'succeeded';
        state.RCSEditName = action.payload.name;
      })
      .addCase(syncRCSEditCloudApiTemplates.pending, (state) => {
        state.status.syncRCSEditCloudApiTemplates = 'loading';
      })
      .addCase(syncRCSEditCloudApiTemplates.fulfilled, (state) => {
        state.status.syncRCSEditCloudApiTemplates = 'succeeded';
        console.log('Synced RCSEdit Cloud API Templates');
      })
      .addCase(resetApiQrConnection.pending, (state) => {
        state.status.update = 'loading';
      })
      .addCase(resetApiQrConnection.fulfilled, (state) => {
        state.status.update = 'succeeded';
      })
      .addCase(unlinkRCSEdit.pending, (state) => {
        state.status.update = 'loading';
      })
      .addCase(unlinkRCSEdit.fulfilled, (state) => {
        state.status.update = 'succeeded';
      });
  },
});

export const setSchedule =
  (scheduleId: string) => (dispatch: Dispatch, getState: () => RootState) => {
    const scheduleSliceState = getState().Schedule;
    const schedule = scheduleSliceState.data.find((s) => s.id === scheduleId);

    dispatch(changeSchedule(schedule));
  };

const selectRCSEditState = (state: RootState) => state.RCSEdit;
export const selectRCSEdit = createSelector(
  selectRCSEditState,
  (state) => state.RCSEdit
);
export const selectRCSEditName = createSelector(
  selectRCSEditState,
  (state) => state.RCSEditName
);
export const selectRCSEditFetchStatus = createSelector(
  selectRCSEditState,
  (state) => state.status.fetch
);
export const selectRCSEditUpdateStatus = createSelector(
  selectRCSEditState,
  (state) => state.status.update
);
export const selectSyncRCSEditCloudApiTemplatesStatus = createSelector(
  selectRCSEditState,
  (state) => state.status.syncRCSEditCloudApiTemplates
);
export const selectResetApiQrConnectionStatus = createSelector(
  selectRCSEditState,
  (state) => state.status.resetApiQrConnection
);
export const selectUnlinkRCSEditStatus = createSelector(
  selectRCSEditState,
  (state) => state.status.unlinkRCSEdit
);

export const {
  changeName,
  changeAssignTo,
  changeUseInCampaigns,
  changeToken,
  changeReassign,
  changeReassignGroup,
  changeAutoResponseBusinessHours,
  changeAutoResponseOutOfBusinessHours,
  changeAutoResponseHoliday,
  changeSchedule,
  addPermission,
  changePermissionReadMessages,
  changePermissionStartConversation,
  removePermission,
  changeAssignMethod,
  changeReOpenTime,
  changePauseBetweenMessages,
  changeUniverseDomain,
  changeType,
  changeTokenUri,
  changeProjectId,
  changePrivateKeyId,
  changePrivateKey,
  changeClientX509,
  changeClientId,
  changeAuthUri,
  changeClientEmail,
  changeAuthProviderX509

} = RCSEditSlice.actions;

export default RCSEditSlice.reducer;
