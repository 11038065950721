import { useEffect, useMemo } from 'react';
import Router from './router';
// Types
import RootProps from 'types/RootProps';
// Context
import { UserInfoProvider } from 'context/UserInfoProvider/UserInfoProvider';
// Theme
import { createTheme, ThemeProvider } from '@mui/material';
// Redux
import { getUser, selectUser, setUser } from './redux/features/userSlice/userSlice';
import { useSelector } from 'react-redux';

import {
  fetchSpaceInfo,
  selectSpaceInfo,
  setSpaceInfo,
  setSpaceFetchStatus,
  setSpaceUrls,
} from './redux/features/spaceSlice/spaceSlice';
import {
  selectedTheme,
  toggleTheme,
  selectThemeToggleStatus,
} from './redux/features/themeSlice';
// Components/ui
import { PageLoading } from './components';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
import './App.css';
import MuiComponentsConfig from 'style/MuiComponentesConfig';

interface Props {
  rootProps: RootProps;
}

const App = ({ rootProps }: Props) => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const space = useSelector(selectSpaceInfo);
  const themeRedux = useSelector(selectedTheme);
  const theme = useMemo(() => MuiComponentsConfig(themeRedux), [themeRedux]);
  const themeToggleStatus = useSelector(selectThemeToggleStatus);

  useEffect(() => {
    const fetchData = async () => {
      if (rootProps?.spaceInfo && rootProps?.userInfo) {
        const { spaceInfo, userInfo } = rootProps;
        dispatch(setSpaceInfo(spaceInfo));
        dispatch(setSpaceFetchStatus('succeeded'));
        dispatch(setSpaceUrls(spaceInfo.id));
        dispatch(setUser(userInfo));
      } else {
        // If start:standalone
        // First fetchs space info  
        await dispatch(fetchSpaceInfo());
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (!rootProps?.spaceInfo && !rootProps?.userInfo && space) {
      // Second fetchs user info (space info needed for this call)
      dispatch(getUser());
    }
  }, [space]);
  useEffect(() => {
    if (user !== null) {
      dispatch(toggleTheme(user.theme));
    }
  }, [user, dispatch]);

  // Load the google script
  useEffect(() => {
    // Load the google script and append it to the document body
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
      <UserInfoProvider>
        <ThemeProvider theme={theme}>
          {user && space && themeToggleStatus === 'toggled' ? (
            <Router />
          ) : (
            <PageLoading />
          )}
        </ThemeProvider>
      </UserInfoProvider>
  );
};

export default App;
