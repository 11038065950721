

import { selectedTheme } from "../../../../../redux/features/themeSlice.js";

// Translations
import { useTranslation } from "react-i18next";
// Components/ui
import { Box, CircularProgress, Link, Typography } from "@mui/material";

import { OpenInNew } from "@mui/icons-material";
// Router
import { useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";// utils
import appList from "./utils/appList";
import { useSelector } from "react-redux";
import { Theme } from "@mui/system";
import { useContext } from "react";
import useImagePreloader from "hooks/useImagePreloader";
import { UserTrii } from "@trii/types/dist/Users";
//Icons
import TriiCalendarIcon from './images/TriiCalendar.svg';
import TriiCallIcon from './images/TriiCall.svg';
import TriiChatIcon from './images/TriiChat.svg';
import TriiCollectionIcon from './images/TriiCollection.svg';
import TriiContactsIcon from './images/TriiContacts.svg';
import TriiCRMIcon from './images/TriiCRM.svg';
import TriiMarketingIcon from './images/TriiMarketing.svg';
import TriiServiceIcon from './images/TriiService.svg';
import TriiTeamIcon from './images/TriiTeam.svg';
import TriiTicketsIcon from './images/TriiTickets.svg';
import TriiSignatureIcon from './images/TriiSignature.svg';
import TriiDataSyncIcon from './images/TriiDataSync.svg';
import appDataList from "./utils/appDataList";
interface AppsMenuProps {
    user: UserTrii;
  }
const MenuIcons = ({ user }: AppsMenuProps) => {
    const { t } = useTranslation();
  const imageList = appList.map((app) => app.icon);
  const imageDataList = appDataList.map((app) => app.icon);
  const { imagesPreloaded } = useImagePreloader([...imageList, ...imageDataList]);
  const navigate = useNavigate();
  
  const openLinkNewTab = (event, url) => {
    event.preventDefault();
    window.open(url, "_blank", "noopener,noreferrer");
  };



  const onSelectedTheme: Theme = useSelector(selectedTheme);

  const profileApps = [
    { name: "dropdownAppList.TriiContacts", icon: TriiContactsIcon, enabled: user.profile.contacts.enabled, url: '/a/contacts' },
    { name: "dropdownAppList.TriiConversations", icon: TriiChatIcon, enabled: user.profile.conversations.enabled, url: '/a/conversations' },
    { name: "dropdownAppList.TriiChat", icon: TriiTeamIcon, enabled: user.profile.chat.enabled, url: '/a/chat' },
    // { name: "dropdownAppList.TriiMarketing", icon: TriiMarketingIcon, enabled: user.profile.marketing.enabled, url: '/a/marketing' },
    // { name: "dropdownAppList.TriiCalls", icon: TriiCallIcon, enabled: user.profile.calls.enabled, url: '/a/calls' },
    // { name: "dropdownAppList.TriiDebtCollection", icon: TriiCollectionIcon, enabled: user.profile.debtCollection.enabled, url: '/a/debtcollection' },
    // { name: "dropdownAppList.TriiRepair", icon: TriiServiceIcon, enabled: user.profile.service.enabled, url: '/a/repair' },
    // { name: "dropdownAppList.TriiTickets", icon: TriiTicketsIcon, enabled: user.profile.tickets.enabled, url: '/a/tickets' },
    // { name: "dropdownAppList.TriiCalendar", icon: TriiCalendarIcon, enabled: user.profile.calendar.enabled, url: '/a/calendar'},
    // { name: "dropdownAppList.TriiCRM", icon: TriiCRMIcon, enabled: user.profile.crm.enabled, url: '/a/CRM' }
  ];


    return (
        <Box
        sx={{
          display: "grid",
          flexDirection: "column",
          placeItems: "center",
          overflowY: "auto",
          width: '100%'
        }}>
        {!imagesPreloaded ? (
          <CircularProgress
            sx={{
              color: onSelectedTheme.palette.primary.main,
              margin: "1rem auto",
            }}
          />
        ) : (
          <ul
            className="home-app-list"
          >
            {profileApps.map((app, index) => (
              <li key={index}>
                {app.enabled ? (
                  <RouterLink to={app.url}>
                    <Link
                      color={onSelectedTheme.palette.primary.main}
                      className="home-app-list-item hover-white home-position-relative"
                      sx={{
                        "&:hover": {
                          backgroundColor: (theme) =>
                            `${theme.palette.grey[200]}30`,
                          color: onSelectedTheme.palette.primary.main,
                        },
                      }}                    >
                      <OpenInNew
                        className="home-iconCornerRight"
                        sx={{ display: "none" }}
                        onClick={(e) => openLinkNewTab(e, app.url)}
                      />
                      <span className="home-icon-stack">
                        <img alt="" src={app.icon} />
                      </span>
                      <Typography
                        variant="body2"
                        color={onSelectedTheme.palette.text.primary}
                        className="home-app-list-name"
                        mt={"0.25rem"}
                      >
                        {t(app.name)}
                      </Typography>
                    </Link>
                  </RouterLink>
                ) : (
                  <Link
                    color={onSelectedTheme.palette.primary.main}
                    className="disabled home-app-list-item hover-white home-position-relative"
                    sx={{
                      "&:hover": {
                        backgroundColor: (theme) =>
                          `${theme.palette.grey[200]}30`,
                        color: onSelectedTheme.palette.primary.main,
                      },
                    }}
                  >
                    <span className="home-icon-stack">
                      <img alt="" src={app.icon} />
                    </span>
                    <Typography
                      variant="body2"
                      color={onSelectedTheme.palette.text.primary}
                      className="home-app-list-name"
                      mt={"0.25rem"}
                    >
                      {t(app.name)}
                    </Typography>
                  </Link>
                )}
              </li>
            ))}       
          </ul>
        )}
      </Box>
    )
}

export default MenuIcons